// Gatsby supports TypeScript natively!
import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Acerca = () => {

  useEffect(() => {
    window.slider()
  }, [])

  return (
    <Layout title="Acerca de nosotros" show_bread={true}>
      <SEO title="Acerca de nosotros" />
      <div className={"section mcb-section equal-height"} style={{ paddingTop: "100px", paddingBottom: "60px" }}>
        <div className={"section_wrapper mcb-section-inner"}>
          <div className={"wrap mcb-wrap one  valign-top clearfix"}>
            <div className={"mcb-wrap-inner"}>
              <div className={"container-custom"}>
                <div className={"row"}>
                  <div className={"col-md-6"}>
                    <div style={{ display: "inline", verticalAlign: "middle" }}>
                      <br />
                      <br />
                      <p>Apalache Análisis surge de la necesidad del mercado financiero mexicano por
                        obtener información confiable, íntegra, independiente y profesional para tomar
                        decisiones de inversión. Los servicios que ofrece son valuación de vehículos
                        de inversión y cobertura de emisores.
                      </p>
                      <br />

                      <p>
                        El equipo suma más de 5 décadas de experiencia en el ramo, además de las
                        certificaciones más reconocidas a nivel global, mejores prácticas y reconocimiento
                        institucional, que avalan la calidad del servicio.
                      </p>
                    </div>
                  </div>
                  <div className={"col-md-6 text-center"}>
                    <img alt="" src={"/images/acerca.png"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Acerca
